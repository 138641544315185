<template>
  <div class="statement-status">
    <div class="statement-status__status">
      <Icon :path="mdiClockTimeFive" :size="16" />
      Ongoing
      <Icon
        :path="mdiAlertCircleOutline"
        :size="16"
        class="statement-status__icon" />
    </div>
    <div class="statement-status-popup">
      <p class="statement-status-popup__title">Statement ongoing</p>
      <p class="statement-status-popup__text">
        This statement has not yet been finalised, meaning these numbers may
        change.
      </p>
    </div>
  </div>
</template>

<script>
  import { mdiAlertCircleOutline, mdiClockTimeFive } from "@mdi/js";

  export default {
    props: {
      isSealed: {
        type: Boolean,
      },
    },

    data() {
      return {
        mdiAlertCircleOutline,
        mdiClockTimeFive,
      };
    },
  };
</script>

<style scoped lang="scss">
  .statement-status {
    position: relative;
    cursor: pointer;

    &__status {
      color: $col_alpha;
      @include flex-center($jc: flex-start, $gap: 0.25rem);
    }

    &:hover {
      .statement-status-popup {
        display: block;
      }
    }
  }

  .statement-status-popup {
    display: none;
    position: absolute;
    width: 300px;
    top: 90%;
    left: 0;
    background: $col_white;
    padding: 0 15px;
    border-radius: $card_radius;
    box-shadow: $card_shadow;
    z-index: 1;

    &__title {
      color: $col_alpha;
      font-weight: bold;
    }

    &__text {
      color: initial;
    }
  }
</style>
