<template>
    <button
        :type="type"
        :disabled="disabled"
        :class="{ text: isTextStyle, secondary: isSecondaryColor, black: isBlack }"
    >
        <Icon
            :path="path"
            :size="16"
        />{{ text }}
    </button>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'button'
        },
        text: {
            type: String,
        },
        path: {
            type: String
        },
        isTextStyle: {
            type: Boolean,
            default: false
        },
        isSecondaryColor: {
            type: Boolean,
            default: false
        },
        isBlack: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
button {
    @include contained-button;
    font-weight: 500;

    &.text {
        @include reset-padding-margin;
        @include text-button;
    }
}
</style>